var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AutheticationGuard",
    [
      _c(
        "div",
        [
          _c("Navbar"),
          _vm.loading || _vm.isError
            ? _c("div", { staticClass: "loader-container" }, [
                _c(
                  "div",
                  { staticClass: "loading-wrapper" },
                  [
                    _c("v-progress-linear", {
                      attrs: {
                        value: "3",
                        height: "3",
                        indeterminate: "",
                        color: "black",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.loading && !_vm.isError
            ? _c("div", { staticClass: "login-container" }, [
                _c("div", { staticClass: "page-wrapper" }, [
                  Object.keys(_vm.vcsData).length > 0
                    ? _c("div", { staticClass: "alertHeader" }, [
                        _c("div", { staticClass: "alert alert-warning" }, [
                          _vm._v("\n            You were referred by VC:  "),
                          _c("b", { staticStyle: { "font-weight": "700" } }, [
                            _vm._v(_vm._s(_vm.vcsData.name)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "page-img" }, [
                    _c("img", {
                      attrs: { src: "/img/landing-page-image.svg", alt: "" },
                    }),
                  ]),
                  _c("div", { staticClass: "signup-options" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78rc3yunxe8cak&redirect_uri=" +
                            _vm.$Website +
                            "/linkedin-verify&state=" +
                            _vm.customerType +
                            "&scope=r_basicprofile%20r_emailaddress",
                        },
                      },
                      [
                        _c("div", { staticClass: "connect-btn" }, [
                          _c("img", {
                            attrs: { src: "/img/onboarding/LinkedIn.svg" },
                          }),
                          _c("span", [_vm._v("Sign in with LinkedIn")]),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "google-signup" },
                    [_c("GoogleSignIn")],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }