<template>
  <AutheticationGuard>
    <div>
      <Navbar />
      <div v-if="loading || isError" class="loader-container">
        <div class="loading-wrapper">
          <v-progress-linear
            value="3"
            height="3"
            indeterminate
            color="black"
          ></v-progress-linear>
        </div>
      </div>

      <div v-if="!loading && !isError" class="login-container">
        <div class="page-wrapper">
          <div class="alertHeader" v-if="Object.keys(vcsData).length > 0">
            <div class="alert alert-warning">
              You were referred by VC:  <b style="font-weight: 700;">{{ vcsData.name }}</b>
            </div>
          </div>

          <div class="page-img">
            <img src="/img/landing-page-image.svg" alt="" />
          </div>
          <div class="signup-options">
            <!-- <a :href="$linkedinRedirectURL"> -->
            <!--  :href="`mailto:matchOps@grupa.io?Subject=Interested in ${startup.name}`" -->
            <a
              :href="`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78rc3yunxe8cak&redirect_uri=${$Website}/linkedin-verify&state=${customerType}&scope=r_basicprofile%20r_emailaddress`"
            >
              <div class="connect-btn">
                <img src="/img/onboarding/LinkedIn.svg" />
                <span>Sign in with LinkedIn</span>
              </div>
            </a>
          </div>
          <div class="google-signup">
            <GoogleSignIn />
          </div>
        </div>
      </div>
    </div>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
  </AutheticationGuard>
</template>

<script>
import Axios from "axios";
import { mapActions, mapState } from "vuex";
import AutheticationGuard from "@/components/auth/AuthGuard";
import Navbar from "@/components/Navbar/onboarding_navbar";
import GoogleSignIn from "../googleLogin";
import mixPanel from "@/mixins/mixPanel";
import postLogin from "@/mixins/postLogin";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
export default {
  mixins: [postLogin, mixPanel],
  props: {},
  components: {
    Navbar,
    AlertSuccess,
    AutheticationGuard,
    GoogleSignIn,
    AlertSuccess,
    AlertError,
  },
  data: () => ({
    loading: false,
    isError: false,
    vcsData: [],
  }),
  created() {
    if (localStorage.getItem("vcs") !== null) {
      this.vcsData = JSON.parse(localStorage.getItem("vcs"));
    }

    // this.setCustomerParamsType();
  },
  mounted() {
    this.setCustomerParamsType();
  },
  methods: {
    ...mapActions("userManagement", [
      "setLinkedinLogin",
      "setAutheticatedUser",
      "setUserReferralCode",
      "setCustomerType",
    ]),
    ...mapActions("devOnboarding", ["setLinkedinProfile"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    setCustomerParamsType() {
      if (this.$route.query.type) {
        let userType = this.$route.query.type;
        let location = this.$route.query.loc;
        if (location.length > 0) {
          if (location === "google") {
            this.location = true;
          }
        }
        let customerType =
          userType === "founder" ? 2 : userType === "expert" ? 1 : "";

        this.setCustomerType(customerType);
      } else {
        if (this.customerType == "" || this.customerType === null) {
          //this.isError = true;
          // console.log(this.$Website + "-" + this.customerType);
          /*  alert(
            "User category not detected. \nKindly choose if you are a founder or an engineer to continue"
          );
          document.location.href = `${this.$Website}`;  */
        } else {
          console.log(this.$Website + "-" + this.customerType);
          this.setCustomerType(this.customerType);
        }
      }
    },
    handleGoogleSuccess(response) {
      this.loading = true;
      if (response) {
        const userDetails = response.getBasicProfile();
        const displayName = userDetails.getName();
        const email = userDetails.getEmail();
        const fullName = displayName.split("\\s+")[0];
        let firstName = fullName.split(" ")[0];
        let lastName = fullName.split(" ")[1];
        firstName = firstName ? firstName : fullName;
        lastName = lastName ? lastName : fullName;
        let payload = { firstName, lastName, email };
        this.handleCreateUser(payload);
      }
    },
    async handleCreateUser(payload) {
      let referralCode = this.referralCode;
      if (referralCode) {
        payload.referralCode = referralCode;
      }
      await Axios.post(this.$baseUrl + `/onboarding/google/sign_in`, payload)
        .then((response) => {
          if (response.data.status == 1) {
            let { user, token, team } = response.data.data;
            user.token = token;
            this.setLogInUser(user);
            if (user.user_type == 1) {
              let payloadData = { data: null, profileUrl: null };
              // clear saved linkedinProfile
              this.setLinkedinProfile(payloadData);
              if (team) {
                this.teamActivePage(user, team);
              } else {
                // if (user.activation_level >= 2) {
                //   this.$router.push({ name: "team_option" });
                // } else {
                //   this.$router.push({ name: "linkedin_profile" });
                // }
                this.updateTeam(token, user);
              }
            } else if (
              user.user_type == 2 ||
              user.user_type == 5 ||
              !user.user_type
            ) {
              this.$router.push({ name: "user_type" });
            }
            this.mixPanelTrack({
              event: "GoogleSignIn",
              email: user.email,
              userType:
                user.user_type == 1
                  ? "dev"
                  : user.user_type == 2
                  ? "client"
                  : "Not Selected",
            });
            this.setUserReferralCode(null);
          } else {
            this.mixPanelTrack({
              event: "GoogleSignInFailed",
              email: null,
              message: "User Authetication Failed-3",
            });
            this.$router.push({ name: "login" });
          }
        })
        .catch((error) => {
          this.mixPanelTrack({
            event: "GoogleSignInFailed",
            email: null,
            message: "User Authetication Failed-3.1",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setLogInUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      localStorage.setItem("grupa_user", JSON.stringify(user));
      this.setAutheticatedUser(payload);
    },
    updateTeam(token, user) {
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      };
      Axios.get(this.$baseUrl + `/onboarding/set/usertype/1`, header)
        .then((response) => {
          if (response.data.status == 1) {
            const { user } = response.data.data;
            this.updateLoginUser(user);
            if (user.activation_level >= 2) {
              this.$router.push({ name: "team_option" });
            } else {
              this.$router.push({ name: "linkedin_profile" });
            }
          }
        })
        .catch((error) => {});
    },
    updateLoginUser(user) {
      let payload = {
        user,
        isAuthenticated: true,
        userType: user.user_type,
      };
      this.setAutheticatedUser(payload);
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "customerType"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 80px);
}
.page-wrapper {
  width: 410px;
}
.signup-options {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signup-options a {
  text-decoration: none;
}
.connect-btn {
  background: #0288d1;
  box-shadow: 1px 2px 5px rgba(21, 23, 26, 0.2);
  border-radius: 4px;
  padding: 9px;
  width: 410px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
}
.connect-btn img {
  margin-right: 80px;
}
.page-img {
  height: 217px;
}

.alertHeader {
  margin-bottom: 50px;
}
</style>
